import React from 'react';
import {List} from '@mui/material';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: 'relative',
  },
}));

const CmtVertical = props => {
  const { menuItems } = props;
  const {groups} = useSelector(({auth})=> auth)

  const classes = useStyles();
  return (
      // <BrowserRouter>
        <List component="nav" disablePadding className={classes.sideNavMenu}>
          {menuItems.map((item, index) => {
            switch (item.type) {
              case 'section':
                return item.checkGroup(groups)&&<NavSection {...item} groups={groups} key={index}/>;
              case 'collapse':
                return item.checkGroup(groups)&&<NavCollapse {...item} key={index}/>;
              case 'item':
                return item.checkGroup(groups)&&<NavMenuItem {...item} key={index}/>;
              default:
                return null;
            }
          })}
        </List>
      // </BrowserRouter>

  );
};

export default CmtVertical;
