import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const Dashboards = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/fee`} component={lazy(() => import('./Fee'))} />
        <Route path={`${requestedUrl}/payment`} component={lazy(() => import('./Payment'))} />
        <Route path={`${requestedUrl}/receipt`} component={lazy(() => import('./Receipt'))} />
        <Route path={`${requestedUrl}/log-bank-transfer`} component={lazy(() => import('./Log'))} />
        {/*<Route component={lazy(() => import('../ExtraPages/404'))} />*/}
      </Switch>
    </Suspense>
  );
};

export default Dashboards;
