// import createBreakpoints from '@mui/material/styles/createBreakpoints'
import { Fonts } from '@jumbo/constants/ThemeOptions';
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const defaultTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  direction: 'ltr',
  palette: {
    type: 'light',
    common: {
      black: '#000000',
      white: '#ffffff',
      dark: '#020202',
    },
    primary: {
      main: '#6200EE',
      dark: '#3700B3',
      contrastText: '#fff',
    },
    secondary: {
      main: '#03DAC5',
      dark: '#018786',
      contrastText: '#fff',
    },
    success: {
      light: '#D7F5B1',
      main: '#8DCD03',
      dark: '#5D9405',
      contrastText: '#fff',
    },
    info: {
      light: '#9BE7FD',
      main: '#0795F4',
      dark: '#0356AF',
      contrastText: '#fff',
    },
    warning: {
      light: '#FFDE99',
      main: '#FF8C00',
      dark: '#D36F1A',
      contrastText: '#fff',
    },
    error: {
      light: '#FFC7D1',
      main: '#E00930',
      dark: '#87061E',
      contrastText: '#fff',
    },
    momo: {
      light: '#dd73b6',
      main: '#F74584',
      dark: '#E00930',
      contrastText: '#fff',
    },
    dark: {
      light: '#8c8c8c',
      main: '#262626',
      dark: '#000000',
      contrastText: '#fff',
    },
    sidebar: {
      bgColor: '#ffffff',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#6200EE',
      navHoverBgColor: 'rgb(229, 229, 229)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    btn: {
      hover: 'rgba(0, 0, 0, 0.08)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    popupColor: {
      main: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: Fonts.PRIMARY,
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 'bold',
    fontWeightExtraBold: 800,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 20,
          fontWeight: 'bold',
          [breakpoints.up('md')]: {
            fontSize: 22,
          },
        },
        h2: {
          fontSize: 18,
          fontWeight: 'bold',
          [breakpoints.up('md')]: {
            fontSize: 20,
          },
        },
        h3: {
          fontSize: 16,
          fontWeight: 'bold',
          [breakpoints.up('md')]: {
            fontSize: 18,
          },
        },
        h4: {
          fontSize: 16,
          fontWeight: 'bold',
        },
        h5: {
          fontSize: 14,
          fontWeight: 400,
        },
        h6: {
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 0.5,
        },
        subtitle1: {
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.15,
        },
        subtitle2: {
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 0.1,
        },
        body1: {
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.5,
        },
        body2: {
          fontSize: 14,
          fontWeight: 400,
          letterSpacing: 0.25,
        },
      }
    },
    MuiButton: {
      styleOverrides:{
        root: {
          fontWeight: 'bold',
          letterSpacing: 1.25,
          fontSize: 13,
        }
      },
    },
    MuiToggleButton: {
      styleOverrides:{
        root: {
          borderRadius: 4,
        },},
    },
    MuiCardLg: {
      styleOverrides:{
        root: {
          borderRadius: 10,
        },},
    },
    MuiCard: {
      styleOverrides:{
        root: {
          borderRadius: 4,
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
        },},
    },
    MuiTab: {
      styleOverrides:{
        textColorPrimary: {
          color: 'rgba(0, 0, 0, 0.87)',
        },},
    },
    MuiPopover: {
      styleOverrides:{
        paper: {
          backgroundColor: '#FFFFFF',
        },},
    },
    MuiDialog: {
      styleOverrides:{
        paper: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      }
    },
    MuiDataGrid: {
      styleOverrides:{
        cell: {
          overflowY: 'auto!important',
        },
        root: {
          '& .MuiDataGrid-filterForm': {
            backgroundColor: 'black!important',
          },
        },
        filterForm: {
          backgroundColor: 'black!important',
        }
      },
    },
    // MuiDataGrid: {
    //   styleOverrides:{
    //
    //   },
    // },
  },
};
export default defaultTheme;
