import React, {useContext, useMemo} from 'react';

import {IntlProvider} from 'react-intl';

import {ThemeProvider} from '@mui/material/styles';
import {createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';

import AppLocale from 'i18n';
import defaultTheme from "../../theme/defaultTheme";
import { create } from 'jss';
import {jssPreset, StylesProvider, createGenerateClassName} from "@mui/styles";
import {StyledEngineProvider} from '@mui/material/styles';

const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
    disableGlobal: true
});



// Configure JSS
const jss = create({plugins: [...jssPreset().plugins]});

const AppWrapper = ({children}) => {
    const {locale} = useContext(AppContext);

    const muiTheme = useMemo(() => {
        return createTheme(defaultTheme);
    }, []);

    return (
        <IntlProvider locale={AppLocale[locale.locale].locale} messages={AppLocale[locale.locale].messages}>
            <ThemeProvider theme={muiTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StyledEngineProvider injectFirst>
                    <StylesProvider jss={jss} generateClassName={generateClassName}>
                            <CssBaseline/>
                            {/*<AppLayout>{children}</AppLayout>*/}
                            {children}
                    </StylesProvider>
                    </StyledEngineProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </IntlProvider>
    );
};

export default AppWrapper;
