import React, {useContext} from 'react';
import FooterLogo from './FooterLogo';
import {Box, Button} from '@mui/material';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
import {THEME_TYPES} from '../../../constants/ThemeOptions';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Footer = props => {
  const classes = useStyles();
  const { themeType } = useContext(AppContext);
  const date = new Date();

  return (
    <Box className={classes.root} {...props}>
      <Box display="flex" alignItems="center">
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <FooterLogo mr={5} color={themeType === THEME_TYPES.DARK ? 'white' : ''} />
        </Box>
        <Box fontSize={{ xs: 12, sm: 14 }} component="p" color="text.disabled">
          Copyright Houze © 2021
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Box component="span" fontSize={16} fontWeight={700} color="primary.main" mr={5}>
            $24 Only
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default Footer;
