import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router';
// import LayoutBuilder from './LayoutBuilder';
import {Redirect, useLocation} from 'react-router-dom';
import Fees from './Fees';
import Xu from './Xu';
import Property from './Property';
import AppLayout from "../@jumbo/components/AppLayout";
import {useSelector} from "react-redux";
import PageLoader from "../@jumbo/components/PageComponents/PageLoader";


const Routes = () => {
    const {token, initURL} = useSelector(({auth}) => auth);
    const location = useLocation();
    // if (token === null && location.pathname !== '/signin'){
    //     return ( <Redirect to={'/signin'}/> );
    // }
    if (!token) {
        return <Redirect to={'/signin'}/>
    }
    if (location.pathname !== '/signin') {
        if (location.pathname === '/') {
            return (<Redirect to={initURL}/>);
        }
    } else return <Redirect to={initURL}/>


    // if (location.pathname === '' || location.pathname === '/') {
    //   return <Redirect to={'/dashboard'} />;
    // } else if (authUser && location.pathname === '/signin') {
    //   return <Redirect to={'/dashboard'} />;
    // }

    return (
        <React.Fragment>
            {token ? <AppLayout>
                <Suspense fallback={<PageLoader/>}>
                    <Switch>

                        <Route path="/fee" component={Fees}/>
                        <Route path="/xu" component={Xu}/>
                        <Route path="/property" component={Property}/>
                        <Route path="/user" component={lazy(() => import('./Users'))}/>
                        <Route path="/ticket" component={lazy(() => import('./Ticket'))}/>
                        <Route path="/facility-booking" component={lazy(() => import('./FacilityBooking'))}/>
                        <Route path="/dashboard" component={lazy(() => import('./Dashboard'))}/>
                        <Route path="/citizen" component={lazy(() => import('./Citizens'))}/>
                        <Route path="/houze-admin" component={lazy(() => import('./HouzeAdmin'))}/>
                        <Route path="/email-send" component={lazy(() => import('./EmailSend'))}/>
                        <Route path="/403" component={lazy(() => import('./ExtraPages/403'))}/>
                        {/*<Route path="/signin" component={SignIn} />*/}
                        {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}

                        {/*<Redirect exact from="/" to="/fee/fee" />*/}
                    </Switch>

                </Suspense>
            </AppLayout> : <Redirect to={'/signin'}/>}
        </React.Fragment>
    );
};

export default Routes;
