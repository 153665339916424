import {
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    GET_PROFILE_USER,
    GET_PROFILE_USER_SUCCESS
} from "@jumbo/constants/ActionTypes";


const INIT_STATE = {
    loader: false,
    initURL: '/fee/fee',
    token: localStorage.getItem('access'),
    profile: {},
    groups: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                token: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                token: null,
                loader: false
            }
        }
        case GET_PROFILE_USER: {
            return {
                ...state
            }
        }
        case GET_PROFILE_USER_SUCCESS: {
            return {
                ...state,
                profile: action.payload.profile,
                groups: action.payload.groups
            }
        }
        default:
            return state;
    }
}
