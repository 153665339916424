import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import {alpha, Box} from '@mui/material';
import ContentLoader from '../../ContentLoader';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@mui/material/Typography';
import {CurrentAuthMethod} from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import {makeStyles} from "@mui/styles";
import {userSignIn,} from 'redux/actions/Auth';
import {history} from "../../../../redux/store";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {token} = useSelector(({auth}) => auth);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const onSubmit = () => {
    dispatch(userSignIn({username, password}));
  };

  useEffect(() => {
    if (token) {
      history.push('/');
    }
  }, [token]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/icons8-superman.svg'} width={300}/>
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form onKeyDown={(event) => {
          if (event.key === 'Enter'){
            onSubmit()
          }
        }}>
          <Box mb={2}>
            <TextField
              label="Username"
              fullWidth
              onChange={event => setUsername(event.target.value)}
              defaultValue={username}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>


          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.signIn" />
            </Button>

          </Box>
        </form>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
