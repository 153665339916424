import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {BrowserRouter, Switch} from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {history, store} from './redux/store';
import AppWrapper from '@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import SignIn from "./@jumbo/components/Common/authComponents/SignIn";
import {Route} from "react-router";
//
// export const store = configureStore();

const App = () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <AppContextProvider>

                <AppWrapper>
                    <Switch>
                        <BrowserRouter >
                            <Route path="/signin" component={SignIn}/>
                            <Route path="" component={Routes}/>
                        </BrowserRouter>

                    </Switch>
                </AppWrapper>
            </AppContextProvider>
        </ConnectedRouter>
    </Provider>
);

export default App;
