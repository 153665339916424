import {
    GET_PROFILE_USER,
    GET_PROFILE_USER_SUCCESS,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS
} from '@jumbo/constants/ActionTypes';

export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};

export const userSignInSuccess = (token) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: token
    }
};

export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};
export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};
export const getProfile = () => {
    return {
        type: GET_PROFILE_USER,
    }
};
export const getProfileSuccess = (payload) => {
    return {
        type: GET_PROFILE_USER_SUCCESS,
        payload: payload
    }
};
