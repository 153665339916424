import React from 'react';
import {Box, Typography} from '@mui/material';
import {NavLink} from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';

const Logo = ({ color, ...props }) => {
  const logoUrl = color === 'white' ? '/images/icons8-superman.svg' : '/images/icons8-superman.svg';
  const logoSymbolUrl = '/images/icons8-superman.svg'

  return (
    <Box className="pointer" {...props}>
      {/*<Box sx={{ display: { xs: 'block', sm: 'none' } }}>*/}
      {/*  <NavLink to="/">*/}
      {/*    <CmtImage src={logoUrl} alt="logo" />*/}
      {/*  </NavLink>*/}
      {/*</Box>*/}
      {/*<Box sx={{ display: { sm: 'block', xs: 'none' } }}>*/}
      {/*  <NavLink to="/">*/}
      {/*    <CmtImage src={logoSymbolUrl} alt="logo" />*/}
      {/*  </NavLink>*/}
      {/*</Box>*/}
    </Box>
  );
};

export default Logo;
