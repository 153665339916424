import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import {hideMessage} from 'redux/actions';
import PageLoader from '../PageComponents/PageLoader';
import {Alert} from "@mui/lab";
import {getProfile} from "../../../redux/actions/Auth";

const ContentLoader = () => {
  const { showMessage, loading, alertMessage } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 2000);
    }

  }, [showMessage]);

  useEffect(()=>{
    dispatch(getProfile())
  }, [])

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      {/*{error && <Snackbar open={Boolean(showMessage)} message={error} />}*/}
      {alertMessage && <Snackbar open={Boolean(showMessage)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
        }
    </React.Fragment>
  );
};

export default ContentLoader;
