import React from 'react';
import VerticalMinimal from './VerticalLayouts/VerticalMinimal';
import globalStyles from "../../theme/GlobalCss";


const AppLayout = ({ children }) => {

  globalStyles();

  return <VerticalMinimal children={children}/>;
};

export default AppLayout;
