import {PERMISSION_DICT} from "@jumbo/constants/StatusDict/User";

const checkGroup = (groupsCheck, groupsCurrent) => {
    return groupsCurrent.some(r => groupsCheck.indexOf(r) >= 0);
}


export const checkSuperAdminPermission = (groupsCurrent) => {
    const groupCheckList = ['is_super']
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkFeeCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.FEE_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkFeeViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.FEE_CREATE , PERMISSION_DICT.FEE_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkTicketCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.TICKET_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkTicketViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.TICKET_CREATE , PERMISSION_DICT.TICKET_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}


export const checkFacilityCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.FACILITY_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkFacilityViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.FACILITY_CREATE , PERMISSION_DICT.FACILITY_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}


export const checkCitizenCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.CITIZEN_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}


export const checkCitizenSetPassPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.CITIZEN_CREATE_PASS]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkCitizenViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.CITIZEN_CREATE , PERMISSION_DICT.CITIZEN_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkXuViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.XU_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkDashboardViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.DASHBOARD_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}
