import React, { useState } from 'react';
import AppContext from './AppContext';
import defaultContext from './defaultContext';

const AppContextProvider = ({ children }) => {
  const [drawerBreakPoint, updateDrawerBreakPoint] = useState(defaultContext.drawerBreakPoint);
  const [headerType, updateHeaderType] = useState(defaultContext.headerType);
  const [sidebarType, updateSidebarType] = useState(defaultContext.sidebarType);

  const [locale, updateLocale] = useState(defaultContext.defaultLng);
  const [isSidebarFixed, eetSidebarPosition] = useState(defaultContext.isSidebarFixed);
  const [sidebarSize, setSidebarSize] = useState(defaultContext.sidebarWidth);




  return (
    <AppContext.Provider
      value={{
        locale,
        updateLocale,
        sidebarSize,
        setSidebarSize,
        drawerBreakPoint,
        updateDrawerBreakPoint,
        headerType,
        updateHeaderType,
        sidebarType,
        updateSidebarType,
        isSidebarFixed,
        eetSidebarPosition,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
