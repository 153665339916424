import {
    useLocation
} from "react-router-dom";

import URLSearchParams from "url-search-params";
import qs from "qs";

const removeQuestionMark = (str) =>{
    while (true){
        if (str.charAt(0) !== '?'){
            break;
        }
        str = str.slice(1)
    }
    return str
}


export const objectToQueryString = (parameter) => {
    let str = [];
    for (let p in parameter)
        if (parameter.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(parameter[p]));
        }
    return '?' + str.join("&");
};

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const cleanObject = (obj) => {
    for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
            delete obj[key];
        }
    }
}

export const setQueryStringWithoutPageReload = qsValue => {
    const newurl = window.location.protocol + "//" +
        window.location.host +
        window.location.pathname +
        qsValue;

    window.history.pushState({path: newurl}, "", newurl);
};

export const setQueryStringValue = (
    key,
    value,
    queryString = removeQuestionMark(window.location.search)
) => {
    const values = qs.parse(queryString);
    const newQsValue = qs.stringify({...values, [key]: value});
    setQueryStringWithoutPageReload(`?${newQsValue}`);
};


export const getQueryStringValue = (
    key,
    queryString = removeQuestionMark(window.location.search)
) => {
    const values = qs.parse(queryString);
    return values[key];
};

