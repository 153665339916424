import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import {makeStyles} from "@mui/styles";
import PaymentIcon from '@mui/icons-material/Payment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import PoolIcon from '@mui/icons-material/Pool';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import TransformIcon from '@mui/icons-material/Transform';
import SettingsIcon from '@mui/icons-material/Settings';
import CopyrightIcon from '@mui/icons-material/Copyright';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import BusinessIcon from '@mui/icons-material/Business';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import {
  checkCitizenViewPermission, checkDashboardViewPermission,
  checkFacilityViewPermission,
  checkFeeViewPermission,
  checkSuperAdminPermission,
  checkTicketViewPermission, checkXuViewPermission
} from "../../../../../util/function/checkGroup";


const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const navigationMenus = [
  {
    name: "Phí",
    icon: <MonetizationOnIcon />,
    type: 'item',
    checkGroup: checkFeeViewPermission,
    link: '/fee/fee',
  },
  {
    name: "Payment",
    icon: <PaymentIcon />,
    type: 'item',
    checkGroup: checkFeeViewPermission,
    link: '/fee/payment',
  },
  {
    name: "Log bank transfer",
    icon: <HistoryEduIcon />,
    type: 'item',
    checkGroup: checkFeeViewPermission,
    link: '/fee/log-bank-transfer',
  },
  {
    name: "Receipt",
    icon: <ReceiptIcon />,
    type: 'item',
    checkGroup: checkFeeViewPermission,
    link: '/fee/receipt',
  },

  {
    name: "User",
    icon: <SupervisedUserCircleIcon />,
    type: 'item',
    checkGroup: checkSuperAdminPermission,
    link: '/user',
  },
  {
    name: "Yêu cầu",
    icon: <ConstructionRoundedIcon />,
    type: 'item',
    checkGroup: checkTicketViewPermission,
    link: '/ticket',
  },
  {
    name: "Đăng ký tiện ích",
    icon: <PoolIcon />,
    type: 'item',
    checkGroup: checkFacilityViewPermission,
    link: '/facility-booking',
  },
  {
    name: "Dashboard",
    icon: <TrendingUpIcon />,
    type: 'item',
    checkGroup: checkDashboardViewPermission,
    link: '/Dashboard',
  },
  {
    name: "Citizen",
    icon: <PeopleOutlineIcon />,
    type: 'item',
    checkGroup: checkCitizenViewPermission,
    link: '/citizen',
  },
  {
    name: "Houze Admin",
    icon: <ManageAccountsIcon />,
    type: 'item',
    checkGroup: checkSuperAdminPermission,
    link: '/houze-admin',
  },
  {
    name: "Email đã gửi",
    icon: <MarkEmailReadIcon />,
    type: 'item',
    checkGroup: checkSuperAdminPermission,
    link: '/email-send',
  },
  {
    name: "Xu",
    icon: <CopyrightIcon />,
    type: 'collapse',
    link: '/xu',
    checkGroup: checkXuViewPermission,
    children: [
      {
        name: "Transaction",
        icon: <TransformIcon />,
        type: 'item',
        checkGroup: checkXuViewPermission,
        link: '/xu/transaction',
      },
      {
        name: "Limit",
        icon: <SettingsIcon />,
        type: 'item',
        checkGroup: checkXuViewPermission,
        link: '/xu/limit',
      },
      {
        name: "Earn configure",
        icon: <PermDataSettingIcon />,
        type: 'item',
        checkGroup: checkXuViewPermission,
        link: '/xu/earn-configure',
      },
    ],
  },
  {
    name: "Property",
    icon: <HomeWorkIcon />,
    type: 'collapse',
    link: '/property',
    checkGroup: checkXuViewPermission,
    children: [
      {
        name: "Building",
        icon: <HomeWorkIcon />,
        type: 'item',
        checkGroup: checkSuperAdminPermission,
        link: '/property/building',
      },
      {
        name: "Company",
        icon: <BusinessIcon />,
        type: 'item',
        checkGroup: checkSuperAdminPermission,
        link: '/property/company',
      },
    ],
  },

];

const SideBar = () => {
  const classes = useStyles();

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
