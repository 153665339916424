import React, { useContext, useState } from 'react';
import { MenuItem, MenuList, Paper, Popover, Typography } from '@mui/material';
import CmtAvatar from '@coremat/CmtAvatar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useDispatch, useSelector} from 'react-redux';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import {makeStyles} from "@mui/styles";
import {getProfile, userSignOut} from "redux/actions/Auth";
import {showMessage} from "redux/actions";
import authApi from "util/services/authApi";
import FormDialog from "../../Dialog/FormDialog";
import UploadImagesDialog from "../../Dialog/UploadImagesDialog";
import ChangePasswordForm from "../../Forms/UserForm/ChangePasswordForm";


const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const {profile} = useSelector(({auth}) => auth);
  const dispatch = useDispatch();

  const [anchorE1, setAnchorE1] = useState(null);

  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({})


  const fetchChangePassword = async (data) =>{
    setSaving(true)
    const response =  await authApi.changePassword(data)
    if (response.message) {
      if(response.response.status === 400){
        setErrors(response.response.data)
        setSaving(false)
      }
      else{
        dispatch(showMessage(response.message))
      }
    } else {
      // setOpenChangePassword(false)
      // setErrors({});
      // setSaving(false)
      dispatch(userSignOut())
    }
  }

  const fetchUpdateAvatar = async (data) =>{
    setSaving(true)
    const response =  await authApi.updateProfile(data)
    if (response.message) {
      if(response.response.status === 400){
        setErrors(response.response.data)
        setSaving(false)
      }
      else{
        dispatch(showMessage(response.message))
      }
    } else {
      setOpenUpload(false);
      setErrors({});
      setSaving(false);
      dispatch(getProfile());
    }
  }


  const handleChangePasswordClose = (check, data) => {
    if(check){
      setSaving(true);
      fetchChangePassword(data);

    }else {
      setOpenChangePassword(false);
      setErrors({});
      setSaving(false)
    }
  };

  const handleUploadClose = (check, pictures) => {
    if (check && pictures.length > 0){
      fetchUpdateAvatar({image: pictures[0]})
    } else {
      setOpenUpload(false);
    }

  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <CmtAvatar src={profile.image_thumb} alt="User Avatar" />
      <div className={classes.userInfo} onClick={handlePopoverOpen}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2">
            <Typography className={classes.userTitle} component="h3" variant="h6">
              {profile.fullname}
            </Typography>
            <Typography className={classes.userSubTitle}>{profile.username}</Typography>
          </div>
          <ArrowDropDownIcon />
        </div>
      </div>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={()=>{setOpenUpload(true)}}>
                <PersonIcon />
                <div className="ml-2">Upload avatar</div>
              </MenuItem>
              <MenuItem onClick={()=>setOpenChangePassword(true)}>
                <SettingsIcon />
                <div className="ml-2">Change password</div>
              </MenuItem>
              <MenuItem onClick={() => {dispatch(userSignOut())}}>
                <ExitToAppIcon />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
      <FormDialog handleClose={handleChangePasswordClose} open={openChangePassword} title={'Đổi mật khẩu'}
                  form={ChangePasswordForm} maxWidth={'xs'} saving={saving} errors={errors}/>
      <UploadImagesDialog handleClose={handleUploadClose} open={openUpload} title={'Cập nhật hình đại diện'} single={true} saving={saving}/>
    </div>
  );
};

export default SidebarHeader;
