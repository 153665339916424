import {ROUTE_NAME, routeTo} from '../routes';
import axiosInstance from "../axiosApi";
import {objectToFormData} from "../function/objectToFormData";


export default {
    login: async (username, password) => {
        return await axiosInstance.post(routeTo(ROUTE_NAME.AUTH.AUTH_TOKEN), {username, password, service:'building'}).then(response => {
            const {access, refresh} = response.data
            localStorage.setItem('access', access);
            localStorage.setItem('refresh', refresh);
            return response
        }).catch(error => error)
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async (params) => {
        const accessToken = localStorage.getItem('access');

        if (accessToken) {
            return Promise.resolve();
        }

        return Promise.reject();

    },
    getProfile: () => {
        return axiosInstance.get(routeTo(ROUTE_NAME.AUTH.PROFILE)).then(response => {
            return response
        }).catch(error => error)
    },

    // called when the user navigates to a new location, to check for permissions / roles
    // getGroups: async () => {
    //     return await axiosInstance.get(routeTo(ROUTE_NAME.AUTH.GET_GROUPS) + 'limit=100').then(response => {
    //         return response
    //     }).catch(error => error)
    // },

    changePassword: async (data) => {
        return await axiosInstance.put(routeTo(ROUTE_NAME.AUTH.CHANGE_PASSWORD), data).then(response => {
            return response
        }).catch(error => error)
    },

    updateProfile: async (data) => {
        return await axiosInstance.patch(routeTo(ROUTE_NAME.AUTH.UPDATE_PROFILE_IMAGE), objectToFormData(data)).then(response => {
            return response
        }).catch(error => error)
    },

};
