import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';
import {history} from "util/function/history";

import rootSaga from '../sagas';

import createSagaMiddleware from 'redux-saga';


const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState = {}) {
  const store = createStore(reducers(history), initialState, bindMiddleware([routeMiddleware, sagaMiddleware]));
  sagaMiddleware.run(rootSaga);
  return store;
}
export const store = configureStore();
export { history };
