import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const Building = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/building`} component={lazy(() => import('./Building'))} />
        <Route path={`${requestedUrl}/company`} component={lazy(() => import('./Company'))} />
        {/*<Route component={lazy(() => import('../ExtraPages/404'))} />*/}
      </Switch>
    </Suspense>
  );
};

export default Building;
