import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useForm} from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';


const FormDialog = ({open, handleClose, title, form, maxWidth='sm', saving=false, initState={}, errors={}, extraProps={}, initData={}}) => {

    const {register, handleSubmit, setValue, control} = useForm();
    const onSubmit = data => {
        handleClose(true, data)
    };

    useEffect(() => {
        Object.keys(initData).map(key => {
            register({name: key});
            setValue(key, initData[key]);
        })
    }, [initData])

    return (
        <Dialog
            scroll='body'
            fullWidth
            maxWidth={maxWidth}
            open={open}
            // onClose={handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {form({register, setValue, control, open, initState, errors , extraProps, initData})}
                </DialogContent>
                <DialogActions>
                    <div className='position-relative'>
                        <Button type={'submit'} color="primary" disabled={saving}>
                            Lưu
                        </Button>
                        {saving && <CircularProgress size={24} className='position-absolute text-primary'/>}
                    </div>
                    <Button onClick={() => handleClose(false, [])} color="warning" autoFocus disabled={saving}>
                        Hủy bỏ
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default FormDialog
