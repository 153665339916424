import defaultTheme from "@jumbo/theme/defaultTheme";
import {DRAWER_BREAK_POINT, HEADER_TYPE, SIDEBAR_TYPE, SIDEBAR_WIDTH} from "@jumbo/constants/ThemeOptions";


export default {
  theme: defaultTheme,
  defaultLng: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  sidebarWidth: SIDEBAR_WIDTH.SMALL,
  sidebarType: SIDEBAR_TYPE.FULL,
  headerType: HEADER_TYPE.FIXED,
  drawerBreakPoint: DRAWER_BREAK_POINT.MD,
  isSidebarFixed: true,
};
