import React, {useEffect, useMemo, useState} from 'react';

import SidebarThemeContext from './SidebarThemeContext';
import {getBackgroundStyle, getOverLayStyle} from '../../CmtHelpers/JssHelper';
import {sidebarLightTheme} from "./sidebarThemeColors";


const SidebarThemeProvider = ({ children }) => {
  const [activeTheme, setActiveTheme] = useState({
    ...sidebarLightTheme,
    backgroundColor: '',
    backgroundImage: '',
    gradientDirection: '',
  });



  const sidebarContextValue = useMemo(() => {
    const backgroundStyle = getBackgroundStyle(
      activeTheme.backgroundColor,
      activeTheme.backgroundImage,
      activeTheme.gradientDirection,
    );
    const overlayStyle = getOverLayStyle({
      colors: activeTheme.backgroundColor,
      opacity: 0.6,
      direction: '180deg',
    });

    return {
      sidebarTheme: activeTheme,
      backgroundStyle: backgroundStyle,
      overlayStyle: overlayStyle,
      setSidebarTheme: setActiveTheme,
    };
  }, [activeTheme]);

  return <SidebarThemeContext.Provider value={sidebarContextValue}>{children}</SidebarThemeContext.Provider>;
};

export default SidebarThemeProvider;
