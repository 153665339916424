import {objectToQueryString} from "./function/queryString";

const {compile} = require("path-to-regexp");

const API_URL = process.env.REACT_APP_ENV === 'prod' ?
    'https://super.houze.io' : process.env.REACT_APP_ENV === 'beta' ? 'https://super.beta.houze.io' : 'https://super.alpha.houze.io';


export const ROUTE_NAME = {
    AUTH: {
        REFRESH_TOKEN: 'auth.refresh.token',
        AUTH_TOKEN: 'auth.login.token',
        PROFILE: 'auth.get.profile',
        CHANGE_PASSWORD: 'CHANGE_PASSWORD',
        UPDATE_PROFILE_IMAGE: 'UPDATE_PROFILE_IMAGE',
    },
    PROPERTY: {
        AUTOCOMPLETE_BUILDINGS: 'AUTOCOMPLETE_BUILDINGS',
        UPDATE_BUILDING: 'UPDATE_BUILDING',

        COMPANIES: 'COMPANIES',
        UPDATE_COMPANY: 'UPDATE_COMPANY',
    },
    USER: {
        GET_USERS: 'GET_USERS',
        CREATE_USER: 'CREATE_USER',
        UPDATE_USER: 'UPDATE_USER',
    },
    CITIZEN: {
        GET_CITIZENS: 'GET_CITIZENS',
        UPDATE_CITIZEN: 'UPDATE_CITIZEN',
        SET_PASS_CITIZEN: 'SET_PASS_CITIZEN',
    },
    HOUZE_ADMIN: {
        CREATE_HOUZE_ADMINS: 'CREATE_HOUZE_ADMINS',
        GET_HOUZE_ADMINS: 'GET_HOUZE_ADMINS',
        UPDATE_HOUZE_ADMIN: 'UPDATE_HOUZE_ADMIN',
    },
    FEE: {
        GET_FEES: 'GET_FEES',
    },
    LOG: {
        GET_LOGS_BANK_TRANSFER: 'GET_LOGS_BANK_TRANSFER',
    },

    RECEIPT: {
        GET_RECEIPTS: 'GET_RECEIPTS',
    },
    PAYMENT: {
        GET_PAYMENTS: 'GET_PAYMENTS',
        UPDATE_PAYMENT: 'UPDATE_PAYMENT',
    },

    BUILDING: {
        GET_BUILDINGS: 'GET_BUILDINGS',
    },
    COMPANY: {
        GET_COMPANY: 'GET_COMPANY',
    },
    TICKET: {
        GET_TICKETS: 'GET_TICKETS',
    },
    FACILITY: {
        GET_FACILITY_BOOKINGS: 'GET_FACILITY_BOOKINGS',
    },
    DASHBOARD: {
        GET_DASHBOARD_USER_BY_MONTH: 'GET_DASHBOARD_USER_BY_MONTH',
        GET_DASHBOARD_USER_GROWTH: 'GET_DASHBOARD_USER_GROWTH',
    },
    MESSAGE: {
        GET_EMAILS: 'GET_EMAILS',
    },
    XU: {
        GET_TRANS: 'GET_TRANS',
        GET_LIMITS: 'GET_LIMITS',
        UPDATE_LIMIT: 'UPDATE_LIMIT',
        GET_EARN_XU_CONFIGS: 'GET_EARN_XU_CONFIGS',
        UPDATE_EARN_XU_CONFIG: 'UPDATE_EARN_XU_CONFIG',
    },
}


const routes_config = {
    [ROUTE_NAME.AUTH.AUTH_TOKEN]: '/auth-service/auth/super/login',
    [ROUTE_NAME.AUTH.PROFILE]: '/manager-service/user/profile/',
    [ROUTE_NAME.AUTH.CHANGE_PASSWORD]: '/manager-service/change-password/',
    [ROUTE_NAME.AUTH.UPDATE_PROFILE_IMAGE]: '/manager-service/update-image/',

    [ROUTE_NAME.AUTH.REFRESH_TOKEN]: '/auth-service/auth/refresh-token',

    [ROUTE_NAME.COMPANY.GET_COMPANY]: '/building-service/company/',


    [ROUTE_NAME.PROPERTY.AUTOCOMPLETE_BUILDINGS]: '/building-service/property/building/',
    [ROUTE_NAME.PROPERTY.UPDATE_BUILDING]: '/building-service/property/building/:id/',

    [ROUTE_NAME.PROPERTY.COMPANIES]: '/building-service/company/',
    [ROUTE_NAME.PROPERTY.UPDATE_COMPANY]: '/building-service/company/:id/',

    [ROUTE_NAME.TICKET.GET_TICKETS]: '/ticket-service/ticket/',


    [ROUTE_NAME.USER.GET_USERS]: '/manager-service/user/',
    [ROUTE_NAME.USER.CREATE_USER]: '/manager-service/user/',
    [ROUTE_NAME.USER.UPDATE_USER]: '/manager-service/user/:id/',

    [ROUTE_NAME.HOUZE_ADMIN.GET_HOUZE_ADMINS]: '/manager-service/manager/houze-admin/',
    [ROUTE_NAME.HOUZE_ADMIN.CREATE_HOUZE_ADMINS]: '/manager-service/manager/houze-admin/',
    [ROUTE_NAME.HOUZE_ADMIN.UPDATE_HOUZE_ADMIN]: '/manager-service/manager/houze-admin/:id/',

    [ROUTE_NAME.CITIZEN.GET_CITIZENS]: '/manager-service/citizen/',
    [ROUTE_NAME.CITIZEN.UPDATE_CITIZEN]: '/manager-service/citizen/:id/',
    [ROUTE_NAME.CITIZEN.SET_PASS_CITIZEN]: '/manager-service/citizen/set-pass/:id/',


    [ROUTE_NAME.FEE.GET_FEES]: '/fee-service/fee/',

    [ROUTE_NAME.RECEIPT.GET_RECEIPTS]: '/fee-service/receipt/',

    [ROUTE_NAME.LOG.GET_LOGS_BANK_TRANSFER]: '/fee-service/log/bank-transfer/',

    [ROUTE_NAME.FACILITY.GET_FACILITY_BOOKINGS]: '/facility-service/booking/',

    [ROUTE_NAME.PAYMENT.GET_PAYMENTS]: '/fee-service/fee-payment/',
    [ROUTE_NAME.PAYMENT.UPDATE_PAYMENT]: '/fee-service/fee-payment/:id/',

    [ROUTE_NAME.MESSAGE.GET_EMAILS]: '/message-service/super/emails/',

    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_USER_BY_MONTH]: '/building-service/dashboard/user-by-month/',
    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_USER_GROWTH]: '/building-service/dashboard/user-growth/',

    [ROUTE_NAME.XU.GET_TRANS]: '/xu-service/transaction/',
    [ROUTE_NAME.XU.GET_LIMITS]: '/xu-service/limit/',
    [ROUTE_NAME.XU.UPDATE_LIMIT]: '/xu-service/limit/:id/',
    [ROUTE_NAME.XU.GET_EARN_XU_CONFIGS]: '/xu-service/building-earn-config/',
    [ROUTE_NAME.XU.UPDATE_EARN_XU_CONFIG]: '/xu-service/building-earn-config/:id/',


}

export const routeTo = (name, params = {}, url_params) => API_URL + compile(routes_config[name])(url_params) + objectToQueryString(params);


export const routeToEndpoint = (endpoint, params = {}) => endpoint + objectToQueryString(params);
