export const USE_STATUS_DICT = {
    ACTIVE: true,
    DISABLE: false,
}


export const USER_STATUS_DISPLAY_DICT = {
    [USE_STATUS_DICT.ACTIVE]: {
        label: "Hoạt động",
        color: "primary",
        variant: "outlined",
    },
    [USE_STATUS_DICT.DISABLE]: {
        label: "Bị khóa",
        color: "error",
        variant: "outlined",
    },
}

export const PERMISSION_DICT = {
    HOUZECONTROLAPP_USE: 'houzecontrolapp_use',

    FEE_VIEW: 'fee_view',
    FEE_CREATE: 'fee_create',

    TICKET_VIEW: 'ticket_view',
    TICKET_CREATE: 'ticket_create',

    FACILITY_VIEW: 'facility_view',
    FACILITY_CREATE: 'facility_create',

    CITIZEN_CREATE: 'citizen_create',
    CITIZEN_CREATE_PASS: 'citizen_pass_create',
    CITIZEN_VIEW: 'citizen_view',

    DASHBOARD_VIEW: 'dashboard_view',

    XU_VIEW: 'xu_view',

    NOTIFICATION_RECEIVE: 'notification_receive',
}

export const PERMISSION_DISPLAY_DICT = {
    [PERMISSION_DICT.HOUZECONTROLAPP_USE]: "Sử dụng app Houze control",

    [PERMISSION_DICT.FEE_VIEW]: "Xem phí",
    [PERMISSION_DICT.FEE_CREATE]: "Tạo cập nhật phí",

    [PERMISSION_DICT.TICKET_VIEW]: "Xem yêu cầu",
    [PERMISSION_DICT.TICKET_CREATE]: "Tạo cập nhật yêu cầu",

    [PERMISSION_DICT.FACILITY_VIEW]: "Xem tiện ích",
    [PERMISSION_DICT.FACILITY_CREATE]: "Tạo cập nhật tiện ích",

    [PERMISSION_DICT.CITIZEN_VIEW]: "Xem cư dân",
    [PERMISSION_DICT.CITIZEN_CREATE]: "Cập nhật cư dân",
    [PERMISSION_DICT.CITIZEN_CREATE_PASS]: "Đặt pass cư dân",

    [PERMISSION_DICT.DASHBOARD_VIEW]: "Xem dashboard",

    [PERMISSION_DICT.XU_VIEW]: "Xem cấu hình xu",

    [PERMISSION_DICT.NOTIFICATION_RECEIVE]: 'Nhận notify dành cho Admin của BQL',
}

export const PERMISSION_GROUP_DISPLAY_DICT = {
    [PERMISSION_DICT.HOUZECONTROLAPP_USE]: "Quản trị hệ thống",

    [PERMISSION_DICT.FEE_VIEW]: "Phí",
    [PERMISSION_DICT.FEE_CREATE]: "Phí",

    [PERMISSION_DICT.TICKET_VIEW]: "Yêu cầu",
    [PERMISSION_DICT.TICKET_CREATE]: "Yêu cầu",

    [PERMISSION_DICT.FACILITY_VIEW]: "Tiện ích",
    [PERMISSION_DICT.FACILITY_CREATE]: "Tiện ích",

    [PERMISSION_DICT.CITIZEN_CREATE]: "Cư dân",
    [PERMISSION_DICT.CITIZEN_VIEW]: "Cư dân",
    [PERMISSION_DICT.CITIZEN_CREATE_PASS]: "Cư dân",

    [PERMISSION_DICT.DASHBOARD_VIEW]: "Dashboard",

    [PERMISSION_DICT.XU_VIEW]: "Cấu hình",

    [PERMISSION_DICT.NOTIFICATION_RECEIVE]: "Thông báo",

}
